import React from "react";
//import App from "next/app";
import {useRouter} from "next/router";
import Layout from "../components/layout/Layout";
import "pxg-web-shared/build/assets/css/main.css";
import "pxg-web-shared/src/styles/scss/loader/style.scss";
//import localizedResources from "../data/localizedResources.json";
import enUSResource from "../locales/en-US/resource.json";
import enCAResource from "../locales/en-CA/resource.json";
import enGBResource from "../locales/en-GB/resource.json";
import jaJPResource from "../locales/ja-JP/resource.json";

function MyApp({Component, pageProps}) {
	const {locale} = useRouter();
	let localeResources;
	switch (locale) {
		case "en-US":
			localeResources = enUSResource;
			break;
		case "en-CA":
			localeResources = enCAResource;
			break;
		case "en-GB":
			localeResources = enGBResource;
			break;
		case "ja-JP":
			localeResources = jaJPResource;
			break;
		default:
			localeResources = enUSResource;
			break;
	}

	pageProps.localeResources = localeResources;
	return (
		<Layout {...pageProps}>
			<Component {...pageProps}></Component>
		</Layout>
	);
}
export default MyApp;
