// taken from here: https://upmostly.com/tutorials/modal-components-react-custom-hooks
// See components/Modal/useModal.js for examples

import React, {useState} from "react";
import ReactDOM from "react-dom";
import PXGIconCloseX from "../elements/PXGIconCloseX.js";
import styles from "./Modal.module.scss";

const Modal = ({modalTitle, modalContent, theme, children, showModal, triggerElement, toggle, showCloseButton}) => {
	const [isBrowser, setIsBrowser] = useState(false);

	const modalRef = React.createRef();
	const [mounted, setMounted] = useState(false);
	const stylesTheme = styles[`${theme}`];

	if (showCloseButton == undefined) {
		showCloseButton = true;
	}

	React.useEffect(() => {
		function keyListener(e) {
			const listener = keyListenersMap.get(e.keyCode);
			return listener && listener(e);
		}
		document.addEventListener("keyup", keyListener);

		setIsBrowser(true);
		// create a starting point for tab and screen reader focus
		// drops the screenreader right into modal
		const startingPoint = modalRef.current;
		if (startingPoint) {
			startingPoint.focus();
		}

		return () => document.removeEventListener("keyup", keyListener);
	}, [showModal]);

	const handleCloseModal = () => {
		if (modalRef.current != null) {
			toggle();
			const returnFocusElement = document.getElementById(triggerElement);
			if (returnFocusElement) {
				console.log(returnFocusElement);
				returnFocusElement.focus();
			}
		}
	};

	const handleTabKey = (e) => {
		if (modalRef.current != null) {
			const focusableModalElements = modalRef.current.querySelectorAll(
				"button, div, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])"
			);
			const firstElement = focusableModalElements[0];
			const lastElement = focusableModalElements[focusableModalElements.length - 1];

			if (focusableModalElements.length === 1) {
				firstElement.focus();
				return e.preventDefault();
			} else {
				if (!e.shiftKey && document.activeElement !== firstElement) {
					firstElement.focus();
					return e.preventDefault();
				}

				if (e.shiftKey && document.activeElement !== lastElement) {
					lastElement.focus();
					return e.preventDefault();
				}
			}
		}
	};

	const keyListenersMap = new Map([
		[13, handleCloseModal],
		[27, handleCloseModal],
		[32, handleCloseModal],
		// [81, handleCloseModal],
		[9, handleTabKey],
	]);

	const content = showModal ? (
		<React.Fragment>
			<div className={styles.overlay}>
				<div
					className={styles.modal}
					ref={modalRef}
					aria-modal="true"
					role="dialog"
					aria-label={modalTitle}
					tabIndex="1">
					{showCloseButton ? (
						<button
							className={`${styles.modalClose} modalCloseTrigger`}
							type="button"
							onClick={handleCloseModal}
							aria-label="click button to close modal or hit escape key. Use screen reader controls to navigate content in modal">
							<PXGIconCloseX />
						</button>
					) : (
						""
					)}
					<div
						id="modalBody"
						className={`${styles.modalBody} modalBodyElement col-12 d-flex flex-column justify-content-center align-items-center`}>
						<div className="row">
							<div className={`${stylesTheme} col-12 mx-auto`}>
								{modalContent ? (
									<div
										dangerouslySetInnerHTML={{
											__html: modalContent,
										}}
									/>
								) : null}
								{children}
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	) : null;
	if (isBrowser) {
		return ReactDOM.createPortal(content, document.body);
	} else {
		return null;
	}
};
export default Modal;
