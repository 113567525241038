function createFullBagInstance(promoCode, accountId, contactId, userId, context) {
	let apiUrl = "/api/fullBag/create";

	return fetch(apiUrl, {
		method: "post",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			promoCode: promoCode,
			accountId: accountId,
			contactId: contactId,
			userId: userId,
		}),
	})
		.then(function (response) {
			return response.json();
		})
		.then(function (data) {
			return data;
		})
		.catch((error) => {
			console.error('Error:', error);
			return error;
		});

}

async function getFullBag(context) {
	let apiUrl = "";
	if (context && context.req) {
		apiUrl = "http://localhost:3000";
	}
	apiUrl += "/api/fullBag";

	// let headers = {};
	// let cookies = {};
	// if (context && context.req != undefined) {
	// 	if (context.req.headers != undefined) {
	// 		headers = context.req.headers;
	// 	}
	// 	if (context.req.cookies != undefined) {
	// 		cookies = context.req.cookies;
	// 	}
	// }

	let fullBagResponse = await fetch(apiUrl, {
	// 	headers: headers,
	// 	cookies: cookies,
	});

	let fullBag = await fullBagResponse.json();

	return fullBag;
}

function saveFullBag(itemGroup, data) {
	return fetch("/api/fullBag", {
		method: "put",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			itemGroup: itemGroup,
			data: data,
		}),
	})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			return data;
		});
}

function emptyFullBag() {
	return fetch("/api/fullBag/empty", {
		method: "put",
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			return data;
		});
}

function clearFullBag() {
	return fetch("/api/fullBag/clear", {
		method: "put",
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			return data;
		});
}

function clearFittingResult() {
	return fetch("/api/fullBag/fittingResult/clear", {
		method: "put",
		headers: {
			"Content-Type": "application/json",
		},
	})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			return data;
		});
}

function updateGrips(grip) {
	return fetch("/api/fullBag/updateGrips", {
		method: "post",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			grip: grip,
		}),
	})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			return data;
		});
}

function updateGripWraps(gripWraps) {
	return fetch("/api/fullBag/updateGripWraps", {
		method: "post",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			gripWraps: gripWraps,
		}),
	})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			return data;
		});
}

function saveOrderDraft() {
	return fetch("/api/fullBag/saveOrderDraft", {
		method: "post",
		headers: {
			"Content-Type": "application/json",
		},
		//body: JSON.stringify(data)
	})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			return data;
		});
}

function getClubCount(fullBag, excludePutter) {
	let driverCount = fullBag && fullBag.Driver && fullBag.Driver.length ? fullBag.Driver.length : 0;
	let fairwayWoodsCount =
		fullBag && fullBag.FairwayWoods && fullBag.FairwayWoods.length ? fullBag.FairwayWoods.length : 0;
	let hybridsCount = fullBag && fullBag.Hybrids && fullBag.Hybrids.length ? fullBag.Hybrids.length : 0;
	let ironsCount = fullBag && fullBag.IronSets ? getIronsCount(fullBag.IronSets) : 0;
	let wedgesCount = fullBag && fullBag.Wedges && fullBag.Wedges.length ? fullBag.Wedges.length : 0;
	let puttersCount =
		fullBag && fullBag.Putters && fullBag.Putters.length && !excludePutter ? fullBag.Putters.length : 0;
	let clubCount = driverCount + fairwayWoodsCount + hybridsCount + ironsCount + wedgesCount + puttersCount;
	return clubCount;
}

function getIronsCount(ironSets) {
	let ironsCount = 0;
	if (ironSets) {
		for (let i = 0; i < ironSets.length; i++) {
			let ironSet = ironSets[i];
			ironsCount += ironSet.clubs ? ironSet.clubs.length : 0;
		}
	}
	return ironsCount;
}

module.exports = {
	createFullBagInstance: (promoCode, accountId, contactId, userId, context) => {
		return createFullBagInstance(promoCode, accountId, contactId, userId, context);
	},
	getFullBag: async (context) => {
		return getFullBag(context);
	},
	saveFullBag: (itemGroup, data) => {
		return saveFullBag(itemGroup, data);
	},
	emptyFullBag: () => {
		return emptyFullBag();
	},
	clearFullBag: () => {
		return clearFullBag();
	},
	clearFittingResult: () => {
		return clearFittingResult();
	},
	updateGrips: (grip) => {
		return updateGrips(grip);
	},
	updateGripWraps: (gripWrap) => {
		return updateGripWraps(gripWrap);
	},
	saveOrderDraft: () => {
		return saveOrderDraft();
	},
	getClubCount(fullBag) {
		return getClubCount(fullBag, false);
	},
	getClubCountWithoutPutter(fullBag) {
		return getClubCount(fullBag, true);
	},
	getIronsCount(ironSets) {
		return getIronsCount(ironSets);
	},
	nextStep(fullBag) {
		if (!fullBag) {
			fullBag = getFullBag();
		}

		let clubCount = getClubCount(fullBag);

		//let clubCountWithoutPutter = getClubCount(fullBag, false);
		//if (clubCount == 14){
		//go to bags

		if (fullBag["Bags"] && fullBag["Bags"].length) {
			//go to Hats
			if (fullBag["Hats"] && fullBag["Hats"].length) {
				window.location = "/review";
			} else {
				window.location = "/hats";
			}
		} else {
			window.location = "/bags";
		}
		//}
		// else if (clubCountWithoutPutter == 13){
		//     //go to putters
		//     window.location = '/clubs/putters'
		// }
		//else {
		//go to clubs
		// window.location = '/clubs'
		//}
	},
};
