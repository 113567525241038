function getOrderItems(context, locale) {
	let apiUrl = "";
	if (context && context.req) {
		apiUrl = "http://localhost:3000";
	}
	apiUrl += "/api/" + locale + "/checkout/getOrderItems";

	let headers = {};
	let cookies = {};
	if (context && context.req != undefined) {
		if (context.req.headers != undefined) {
			headers = context.req.headers;
		}
		if (context.req.cookies != undefined) {
			cookies = context.req.cookies;
		}
	}

	return fetch(apiUrl, {
		headers: headers,
		cookies: cookies,
	})
		.then((response) => {
			return response.json();
		})
		.then((orderItems) => {
			return orderItems;
		})
		.catch((err) => {
			console.log(err);
			return [];
		});
}

import axios from "axios"; // ajax requests
import qs from "qs";

function pushFullBagToSFCCAxios(fullBag, context) {
	var base64encodedData = Buffer.from("storefront" + ":" + "pxg1234").toString("base64");

	let apiUrl =
		"https://bfxb-011.sandbox.us01.dx.commercecloud.salesforce.com/on/demandware.store/Sites-USPXG-Site/en_US/Cart-AddClubBundleXFT?ajax=true";

	// let data = qs.stringify({
	// 	payload: JSON.stringify(fullBag),
	// });

	let config = {
		// headers: {
		// 	//Authorization: "Basic " + base64encodedData,
		// 	//d"Access-Control-Allow-Credentials": true,
		// },
	};

	let data = JSON.stringify([
		[
			{
				attributeId: "clubType",
				selected: "fullbag-promo-0211z-woods",
				isSKU: true,
				mainScope: "clubType",
				scope: "clubType",
			},
		],
		[
			{
				attributeId: "clubNumber",
				selected: "DR-PXG14-9",
				isSKU: true,
				mainScope: "head",
				scope: "head",
			},
			{
				attributeId: "headcover",
				selected: "HC2-2020-DRIVER-BLK",
				isSKU: true,
				mainScope: "head",
			},
			{
				attributeId: "clubLoft",
				selected: "9",
				mainScope: "head",
				scope: "head",
				type: "static",
				isSKU: false,
			},
			{
				attributeId: "clubLie",
				selected: "Standard",
				mainScope: "head",
				scope: "head",
				type: "static",
				isSKU: false,
			},
			{
				attributeId: "webName",
				selected: "S-PROJX-CYPHER-50-5.5",
				isSKU: true,
				mainScope: "shaft",
			},
			{
				attributeId: "shaftLength",
				selected: "Standard - 37 1/2'",
				mainScope: "shaft",
				scope: "shaft",
				isSKU: false,
			},
			{
				attributeId: "clubNumber",
				selected: "G-LAMK-Z5-STD-BLK",
				isSKU: true,
				mainScope: "grip",
			},
		],
		[
			{
				attributeId: "clubNumber",
				selected: "FR-PXG6-3",
				isSKU: true,
				mainScope: "head",
				scope: "head",
			},
			{
				attributeId: "headcover",
				selected: "HC2-2020-FAIRWAY-BLK",
				isSKU: true,
				mainScope: "head",
			},
			{
				attributeId: "clubLoft",
				selected: "3",
				mainScope: "head",
				scope: "head",
				type: "static",
				isSKU: false,
			},
			{
				attributeId: "clubLie",
				selected: "Standard",
				mainScope: "head",
				scope: "head",
				type: "static",
				isSKU: false,
			},
			{
				attributeId: "webName",
				selected: "S-PROJX-CYPHER-50-5.5",
				isSKU: true,
				mainScope: "shaft",
			},
			{
				attributeId: "shaftLength",
				selected: "Standard - 37 1/2'",
				mainScope: "shaft",
				scope: "shaft",
				isSKU: false,
			},
			{
				attributeId: "clubNumber",
				selected: "G-LAMK-Z5-STD-BLK",
				isSKU: true,
				mainScope: "grip",
			},
		],
		[
			{
				attributeId: "clubNumber",
				selected: "HR-PXG6-19",
				isSKU: true,
				mainScope: "head",
				scope: "head",
			},
			{
				attributeId: "headcover",
				selected: "HC2-2020-HYBRID-BLK",
				isSKU: true,
				mainScope: "head",
			},
			{
				attributeId: "clubLoft",
				selected: "19",
				mainScope: "head",
				scope: "head",
				type: "static",
				isSKU: false,
			},
			{
				attributeId: "clubLie",
				selected: "Standard",
				mainScope: "head",
				scope: "head",
				type: "static",
				isSKU: false,
			},
			{
				attributeId: "webName",
				selected: "S-PROJX-CYPHER-60I-5.5",
				isSKU: true,
				mainScope: "shaft",
			},
			{
				attributeId: "shaftLength",
				selected: "Standard - 37 1/2'",
				mainScope: "shaft",
				scope: "shaft",
				isSKU: false,
			},
			{
				attributeId: "clubNumber",
				selected: "G-LAMK-Z5-STD-BLK",
				isSKU: true,
				mainScope: "grip",
			},
		],
		[
			{
				attributeId: "bag",
				selected: "B-UGB8-EP",
				isSKU: true,
				mainScope: "bag",
			},
		],
		[
			{
				attributeId: "hat",
				selected: "H-UHW57-FM-W",
				isSKU: true,
				mainScope: "hat",
			},
		],
	]);

	return axios
		.post(apiUrl, data, config)
		.then(function (response) {
			//console.log(response);
			console.log("sfccResponseJson");
			console.log(response.data);

			if (response.data) {
				//window.location = "https://bfxb-011.sandbox.us01.dx.commercecloud.salesforce.com/s/USPXG/cart";
				alert("redirect to the cart now!");
			} else {
				return {message: "something bad happened"};
			}

			return response.data;
		})
		.catch(function (error) {
			console.log(error);
			return error;
		});
}

function pushFullBagToSFCCFetch(fullBag) {
	console.log("askdjflaksdjf  pushFullBagToSFCCCart!!");

	var base64encodedData = Buffer.from("storefront" + ":" + "pxg1234").toString("base64");

	let apiUrl =
		"https://bfxb-011.sandbox.us01.dx.commercecloud.salesforce.com/on/demandware.store/Sites-USPXG-Site/en_US/Cart-AddClubBundle?ajax=true";

	let data = {
		payload: fullBag,
	};

	return fetch(apiUrl, {
		method: "post",
		mode: "no-cors",
		headers: {
			Authorization: "Basic " + base64encodedData,
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(data),
	})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			return data;
		})
		.catch((err) => {
			console.log(err);
			return err;
		});
}

module.exports = {
	getOrderItems: (context, locale) => {
		return getOrderItems(context, locale);
	},
	pushFullBagToSFCCCart: (fullBag) => {
		return pushFullBagToSFCCAxios(fullBag);
		//return pushFullBagToSFCCFetch(fullBag);
	},
};
