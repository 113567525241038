import React from "react";
import {useRouter} from "next/router";
import styles from "./LocaleSelector.module.scss";
const LocaleSelector = (props) => {
	const {locale} = useRouter();
	const {localeResources} = props;

	function handleOnChangeLocale(event) {
		window.location = event.target.value;
	}

	return (
		<select value={locale} onChange={(e) => handleOnChangeLocale(e)} className={`${styles.PXGSelect} mt-1`}>
			{localeResources.locales
				//.filter((l) => l != defaultLocale)
				.map((loc, index) => (
					<option id="locale" value={loc.value} key={index}>
						{loc.text}
					</option>
				))}
		</select>
	);
};
export default LocaleSelector;
