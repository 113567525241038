module.exports = {
	pushPromoDataToGA: (promo) => {
		if (promo && promo.promo) {
			var gaObject = {
				event: "addToCart",
				ecommerce: {
					currencyCode: promo.promo.currency,
					add: {
						products: [
							{
								sku: promo.promo.sku,
								name: promo.promo.name,
								category: "Clubs/Full Bag Promo",
								price: promo.promo.price,
								quantity: 1,
							},
						],
					},
				},
			};
			dataLayer.push(gaObject);
		}
	},
	pushFittingDataToGA: (fittingType, fittingFormData) => {
		dataLayer.push({
			event: "fittingFormSubmit",
			fittingFormData: {
				fittingType: fittingType,
				...fittingFormData,
			},
		});
		return;
	},
	pushFittingObjectToGA: (itemGroup, result, event, index) => {
		dataLayer.push({
			event: event,
			itemGroup: itemGroup,
			index: index,
			fittingRecommendation: {
				productLine: result.productLine,
				generation: result.generation,
				loft: result.loft,
				shaftLength: result.shaftLength,
				shaft: result.shaftModel
					? {
							brand: result.shaftModel.brand,
							name: result.shaftModel.name,
							webName: result.shaftModel.webName,
							weight: result.shaftModel.weight,
							shaftFlex: result.shaftModel.shaftFlex,
							material: result.shaftModel.material,
					  }
					: {},
				grip: result.gripModel
					? {
							brand: result.gripModel.brand,
							gripSize: result.gripModel.gripSize,
							name: result.gripModel.name,
							webName: result.gripModel.webName,
					  }
					: {},
				clubs: result.clubs ? result.clubs : undefined,
			},
		});
	},
	pushCheckoutEventToGA(checkoutStepNumber, orderItems) {
		let gaObject = {
			event: "checkout",
			ecommerce: {
				checkout: {
					actionField: {
						step: checkoutStepNumber,
					},
					products: [],
				},
			},
		};
		for (let i = 0; i < orderItems.length; i++) {
			const orderItem = orderItems[i];
			gaObject.ecommerce.checkout.products.push({
				sku: orderItem.Sku,
				name: orderItem.ProductName,
				category: orderItem.ProductType,
				price: orderItem.ItemPrice,
				quantity: orderItem.Quantity,
				index: i,
			});
		}
		dataLayer.push(gaObject);
	},
	pushPurchaseEventToGA: (orderId, orderTotal, currency, shippingCost, totalTax, orderItems, cultureCode) => {
		let gaObject = {
			event: "purchase",
			currency: currency,
			transactionId: orderId,
			transactionProducts: [],
			transactionShipping: shippingCost,
			transactionTax: totalTax,
			transactionTotal: orderTotal,
			ecommerce: {
				purchase: {
					actionField: {
						action: "purchase",
						id: orderId,
						revenue: orderTotal,
						tax: totalTax,
						shipping: shippingCost,
						currency: currency,
					},
					products: [],
				},
			},
		};
		for (let i = 0; i < orderItems.length; i++) {
			const item = orderItems[i];
			if (item.Price > 0) {
				gaObject.transactionProducts.push({
					category: "Clubs/Full Bag Promo",
					dimension2: "XtremeFittingTool",
					dimension3: "Web",
					dimension4: cultureCode,
					name: item.ProductName,
					price: item.Price,
					quantity: item.Quantity,
					sku: item.Sku,
					index: i,
				});
				gaObject.ecommerce.purchase.products.push({
					category: "Clubs/Full Bag Promo",
					dimension2: "XtremeFittingTool",
					dimension3: "Web",
					dimension4: cultureCode,
					name: item.ProductName,
					price: item.Price,
					quantity: item.Quantity,
					sku: item.Sku,
					index: i,
				});
			}
		}
		dataLayer.push(gaObject);
	},
	pushRemoveFromCartEventToGA: (orderItems) => {
		let gaObject = {
			event: "removeFromCart",
			ecommerce: {
				remove: {
					products: [],
				},
			},
		};
		for (let i = 0; i < orderItems.length; i++) {
			const orderItem = orderItems[i];
			gaObject.ecommerce.remove.products.push({
				sku: orderItem.Sku,
				name: orderItem.ProductName,
				category: orderItem.ProductType,
				price: orderItem.ItemPrice,
				quantity: orderItem.Quantity,
				index: i,
			});
		}
		dataLayer.push(gaObject);
	},
	pushRestartEventToGA: () => {
		let gaObject = {
			event: "restartEvent",
		};
		dataLayer.push(gaObject);
	},
};
