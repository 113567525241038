import Head from "next/head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import styles from "./Layout.module.scss";
// Global styles
const Layout = (props) => (
	<>
		<Head>
			<meta property="og:image" content="https://www.pxg.com/img/pxg-share.jpg" />
			{/* <script>
                let _dataLayer = [];
            </script>        */}
		</Head>
		<Header {...props} />
		<main className={styles.mainPortal} role="main" id="main">
			{props.children}
		</main>
		<Footer {...props} />
	</>
);

export default Layout;
