import React, {useEffect, useState} from "react";
import systemService from "../../../services/system";
import styles from "./Footer.module.scss";

const Footer = (props) => {
	const {localeResources} = props;
	let d = new Date();
	const [version, setVersion] = useState();

	useEffect(() => {
		systemService.getVersion().then(function (version) {
			setVersion(version);
		});
	}, []);

	return (
		<React.Fragment>
			<footer className="bg_black color_white py-3">
				<div className="content-container">
					<div className="container">
						<div className={`row ${styles.footerCopy}`}>
							<div className={`col-md-4 ${styles.copyright}`}>
								&copy;{d.getFullYear()} {localeResources.parsonsXtremeGolfLLC}
							</div>
							<div className={`col-md-4 text-center`}>
								{version && version.branch ? "Version: " + version.branch : ""}
								{version && version.build ? " Build: " + version.build : ""}
							</div>
							<div className={`col-md-4 ${styles.footerMenu}`}>
								<a
									href={localeResources.privacyPolicyUrl}
									data-eventaction="Full Bag Promo"
									data-eventcategory="Footer"
									data-eventlabel="privacy policy">
									{localeResources.privacyPolicy}
								</a>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</React.Fragment>
	);
};

export default Footer;
