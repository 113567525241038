module.exports = {
	getVersion: () => {
		const url = "/api/system/version";
		return fetch(url)
			.then((response) => {
				return response.json();
			})
			.then((version) => {
				return version;
			});
	},
	getSystemSettings: (context) => {
		const apiUrl = context && context.res ? "http://localhost:3000" : "";
		const url = apiUrl + "/api/system/settings";

		return fetch(url)
			.then((response) => {
				return response.json();
			})
			.then((version) => {
				return version;
			});
	},
};
