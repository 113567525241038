import React from "react";
import {useRouter} from "next/router";
import {PXGIconRefresh} from "pxg-web-shared";
import FullBagService from "../../../../services/fullBag";
import CartService from "../../../../services/cart";
import PXGFullBagButton from "../button/PXGFullBagButton";
import gaHelpers from "../../../../services/googleAnalytics/gaHelpers";
import Modal from "../../modal/Modal";
import useModal from "../../modal/useModal";
import styles from "./PXGRestartLink.module.scss";

const PXGRestartLink = (props) => {
	const {locale} = useRouter();
	const {localeResources} = props;
	const {
		showModal: showRestartConfirm,
		triggerElement: triggerRestartElement,
		toggle: toggleRestartConfirm,
	} = useModal();

	function restart(event) {
		event.preventDefault();
		CartService.getOrderItems(undefined, locale).then((result) => {
			if (result && result.length) {
				gaHelpers.pushRemoveFromCartEventToGA(result);
			}
			gaHelpers.pushRestartEventToGA();
			FullBagService.clearFullBag().then(function () {
				window.location = "/" + locale;
			});
		});
	}
	return (
		<>
			<PXGIconRefresh aria-hidden="true" />
			<button
				onClick={(event) => toggleRestartConfirm(event)}
				aria-label={localeResources.restartFitting}
				className={styles.restartButton}>
				{localeResources.restart}
			</button>
			<Modal
				showModal={showRestartConfirm}
				triggerElement={null}
				toggle={toggleRestartConfirm}
				theme="modalInfoBodySizeSmall">
				<div className="col-12 mb-5 d-flex flex-column justify-content-center align-items-center align-content-center">
					<h2>{localeResources.restart}</h2>
					<div className="h3 m-0 p-0">{localeResources.confirm}</div>
					<p>You would like to restart your fitting</p>
				</div>
				<div className="col-12 d-flex justify-content-between align-items-center align-content-center">
					<PXGFullBagButton
						theme="filledBlack"
						tabIndex="0"
						layoutClasses="mt-2 mx-2"
						onClick={(event) => restart(event)}
						copy={localeResources.restartFitting}
					/>
					<PXGFullBagButton
						theme="filledWhite"
						tabIndex="0"
						layoutClasses="mt-2 mx-2"
						onClick={(event) => toggleRestartConfirm()}
						copy={localeResources.cancel}
					/>
				</div>
			</Modal>
		</>
	);
};

export default PXGRestartLink;
