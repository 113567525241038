import React, {useState} from "react";
import styles from "./PXGFullBagButton.module.scss";

const PXGFullBagButton = (props) => {
	const theme = props.theme;
	const stylesTheme = styles[`${theme}`];
	const layoutClasses = props.layoutClasses;
	const isDisabled = props.isDisabled;
	const dataEventAction = props.dataEventAction;
	const dataEventCategory = props.dataEventCategory;
	const dataEventLabel = props.dataEventLabel;
	const href = props.href;
	const copy = props.copy;

	return (
		<React.Fragment>
			{isDisabled ? (
				<button
					className={`${stylesTheme} ${layoutClasses}`}
					aria-label={props.ariaLabel}
					data-status={`isDisabled`}
					data-eventaction={dataEventAction}
					data-eventcategory={dataEventCategory}
					data-eventlabel={dataEventLabel}
					tabIndex="0"
					href={href}>
					{copy}
				</button>
			) : (
				<button
					className={`${stylesTheme} ${layoutClasses}`}
					aria-label={props.ariaLabel}
					data-eventaction={dataEventAction}
					data-eventcategory={dataEventCategory}
					data-eventlabel={dataEventLabel}
					onClick={props.onClick}
					tabIndex="0"
					href={href}>
					{copy}
				</button>
			)}
		</React.Fragment>
	);
};

export default PXGFullBagButton;
