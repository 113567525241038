// taken from here: https://upmostly.com/tutorials/modal-components-react-custom-hooks
// Example:

// import Modal from "../Modal/Modal";
// import useModal from "../Modal/useModal";
// import modalStyles from "../Modal/Modal.module.scss";
// import infoModalData from '../../data/infoModalData.json';
// const { showModal: showShaftFlexModal, triggerElement: triggerElementShaftFlex, toggle: toggleShaftFlexModal } = useModal();
// const { showModal: showShaftLengthModal, triggerElement: triggerElementShaftLength, toggle: toggleShaftLengthModal } = useModal();

// it's important to indiviudally define modals, so there is never a conflict

{
	/* 
================
	// For pulling content from json data source
	// triggerElement is set if there is an on page trigger, like an info icon 
	// toggleSomeModal() will show/hide the modal
...
	const { showModal: showSomeModal, triggerElement: triggerElementSome, toggle: toggleSomeModal } = useModal();
...
	<button
		id={infoModalData.someJsonDataObject.triggerId}
		className={modalStyles.modalInfoButton}
		onClick={toggleSomeModal}>

		<PXGIconInfo ariaLabel="Insert relevant aria label content" elementClass={styles.iconInfo} />
		<Modal
			modalContent={infoModalData.someJsonDataObject.content}
			showModal={showSomeModal}
			triggerElement={infoModalData.someJsonDataObject.triggerId}
			hide={toggleSomeModal}
		/>
	</button>
================

================
	// for using content as child of <Modal>
	// triggerElement can be left null if logic is what sets the show of the modal
...
	const { showModal: showEmptyBagUseModal, triggerElement: triggerEmptyBagElement, toggle: toggleEmptyBagModal } = useModal();

	function handleEmptyFullBagUseModal(show) {
		toggleEmptyBagModal();
	}
...
	<Modal
		showModal={showEmptyBagUseModal}
		triggerElement={null}
		hide={toggleEmptyBagModal}
	>
		Content goes here
	</Modal>
================
*/
}

import {useState} from "react";

const useModal = () => {
	const [showModal, setShowModal] = useState(false);
	const [triggerElement, setTriggerElement] = useState(false);

	function toggle() {
		setShowModal(!showModal);
		setTriggerElement(triggerElement);
	}

	return {
		showModal,
		triggerElement,
		toggle,
	};
};

export default useModal;
