import React from "react";
import PXGRestartLink from "../../shared/forms/links/PXGRestartLink";
import styles from "./Header.module.scss";
import LocaleSelector from "../../locales/LocaleSelector";

const Header = (props) => {
	const {fullBag, localeResources} = props;
	return (
		<header id="pageHeader" className={`${styles.header} bg_black`}>
			<a href="#main" className={`${styles.skipToMain}`}>
				Skip to main content
			</a>
			<div className="content-container">
				<div className="container">
					<div className={`${styles.containerRow} row d-flex justify-content-center align-items-center`}>
						<div
							className={`${styles.phoneColumn} col-12 col-sm-4 d-flex justify-content-center justify-content-sm-start align-items-center`}>
							{fullBag && fullBag.fullBagGuid && fullBag.promoCode ? <PXGRestartLink {...props} /> : ""}
						</div>
						<div className="col-12 col-sm-4 d-flex justify-content-center align-items-center my-2 my-sm-0">
							<a href="/" className={styles.anchor} aria-label="return to home page">
								<svg
									aria-label="PXG Logo"
									className={styles.svgImage}
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 100 44">
									<title>PXG Logo - Click to Return to Homepage</title>
									<g>
										<g>
											<g>
												<path d="M67.7,0L55.4,18.9l-0.2,0.3l0.2,0.3l12.3,18.9H57l-6.7-10.4l-0.4-0.6l-0.4,0.6l-6.7,10.4H32.1l12.3-18.9 l0.2-0.3l-0.2-0.3L32.1,0h10.7l6.7,10.4l0.4,0.6l0.4-0.6L57,0H67.7" />
											</g>
											<g>
												<g>
													<path d="M34.9,9.9l6.1,9.4L29.1,37.6l-0.5,0.8h-5.1H23v0.5v5h-8.3V19.7H23v9.7v1.7l0.9-1.4l6.5-10l0.2-0.3 l-0.2-0.3l-1.3-1.9L29,17h-0.3H11L6.3,9.9H34.9" />
												</g>
											</g>
											<g>
												<path d="M28.6,0l0.5,0.8l4.1,6.3H4.6L0,0H28.6" />
											</g>
											<g>
												<g>
													<path d="M93.4,9.9L88.8,17H71h-0.3l-0.1,0.2l-1.3,1.9l-0.2,0.3l0.2,0.3l6.5,10l0.9,1.4v-1.7v-9.7H85v24.2h-8.3 v-5v-0.5h-0.5h-5.1l-0.5-0.8L58.7,19.2l6.1-9.4H93.4" />
												</g>
											</g>
											<g>
												<path d="M99.7,0l-4.6,7.2H66.5l4.1-6.3L71.2,0H99.7" />
											</g>
										</g>
									</g>
									<g>
										<path d="M87,41.9c0-1.2,1-2,2-2c1.1,0,2,0.8,2,2c0,1.2-1,2-2,2C88,43.9,87,43.1,87,41.9z M89,40.4 c-0.8,0-1.5,0.6-1.5,1.6c0,0.9,0.7,1.6,1.5,1.6c0.8,0,1.5-0.6,1.5-1.6C90.5,41,89.9,40.4,89,40.4z M88.7,43h-0.4v-2.2h0.8 c0.6,0,0.9,0.2,0.9,0.7c0,0.4-0.2,0.6-0.5,0.6l0.5,1h-0.5l-0.5-0.9h-0.3V43z M88.7,41.8H89c0.3,0,0.4-0.1,0.4-0.3 c0-0.2-0.1-0.3-0.5-0.3h-0.4V41.8z" />
									</g>
								</svg>
							</a>
						</div>
						{fullBag && fullBag.fullBagGuid && fullBag.promoCode ? (
							<div className="col-12 col-sm-4 d-flex justify-content-center justify-content-sm-end align-items-center">
								<a
									href={"tel:" + localeResources.phoneNumber}
									aria-label={localeResources.callSupport + " " + localeResources.phoneNumber}>
									{localeResources.help + " " + localeResources.phoneNumberText}
								</a>
							</div>
						) : (
							<>
								<div className="col-12 col-sm-2 d-flex justify-content-center justify-content-sm-end align-items-center">
									<a
										href={"tel:" + localeResources.phoneNumber}
										aria-label={localeResources.callSupport + " " + localeResources.phoneNumber}>
										{localeResources.help + " " + localeResources.phoneNumberText}
									</a>
								</div>
								<div className="col-12 col-sm-2 d-flex justify-content-center justify-content-sm-end align-items-center">
									<LocaleSelector {...props} />
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</header>
	);
};
export default Header;
